<template>
  <div class="change-phone-wrapper">
    <div class="content-wrapper">
      <div class="card-box">
        <van-field
          class="form-input border-bottom-1px"
          type="tel"
          v-model.trim="changeForm.phone"
          placeholder="请输入手机号"
          :border="false"
          clearable>
          <i class="iconfont iconshouji" slot="left-icon"></i>
        </van-field>
        <van-field
          class="form-input border-bottom-1px"
          v-model.trim="changeForm.code"
          placeholder="请输入验证码"
          :border="false"
          clearable>
          <i class="iconfont iconyanzhengma" slot="left-icon"></i>
          <van-button
            v-show="!isCodePending"
            class="code-btn"
            slot="button"
            size="small"
            type="primary"
            @click="getCode">获取验证码</van-button>
          <span v-show="isCodePending" class="code-text" slot="button">{{codeTime}}</span>
        </van-field>

        <van-button class="change-btn" slot="button" type="primary" @click="changePhone">绑 定</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/api/index'
import { saveLoading } from '@/utils/utils'
const { changeUserPhone, sendMsgCode } = API

export default {
  name: 'changePhone',
  data () {
    return {
      changeForm: {
        phone: '',
        code: ''
      },
      isCodePending: false, // 验证码是否正在获取
      codeTime: 59
    }
  },
  methods: {
    ...mapMutations(['SET_PHONE']),
    handleBack () {
      this.$router.back()
    },
    // 获取验证码
    async getCode (type) {
      let phone = this.changeForm.phone
      if (!phone) {
        this.$toast('手机号不能为空')
        return
      }

      if (/^1[3456789]\d{9}$/.test(phone)) {
        this.isCodePending = true
        let res = await sendMsgCode({
          phone
        })

        if (res.success) {
          this.codeTimer = setInterval(() => {
            this.codeTime--
            if (this.codeTime === 0) {
              this.isCodePending = false
              this.codeTime = 59
              clearInterval(this.codeTimer)
            }
          }, 1000)
        } else {
          this.$toast(res.message)
        }
      } else {
        this.$toast('手机号格式不正确')
      }
    },
    changePhone () {
      let { phone, code } = this.changeForm
      if (!phone) {
        this.$toast('手机号不能为空')
      } else if (!code) {
        this.$toast('验证码不能为空')
      } else {
        saveLoading({
          data: { checkCode: code, phone },
          api: changeUserPhone,
          successMsg: '修改成功',
          success: () => {
            this.SET_PHONE(phone)
            this.handleBack()
          }
        })
      }
    }
  },
  beforeDestroy () {
    this.codeTimer && clearInterval(this.codeTimer)
  }
}
</script>

<style lang="scss" scoped>
  @import '@/common/css/mixin';
  .change-phone-wrapper{
    .content-wrapper{
      padding: 24px;
      .card-box{
        padding: 0 24px 24px;
      }

      .form-input{
        padding: 20px 0;
        &::after{
          left: 0;
        }

        .code-btn{
          width: 160px;
          height: 60px;
          background: #E8E8E8;
          border: none;
          color: #0482FF;
        }
        .code-text{
          color: #0482FF;
        }
        .iconfont{
          font-size: 32px;
        }
      }

      .change-btn{
        width: 654px;
        height: 95px;
        background: linear-gradient(72deg,rgba(5,102,206,1),rgba(17,123,238,1));
        box-shadow:0px 8px 13px 0px rgba(5,102,206,0.43);
        border-radius: 48px;
        border: none;
        margin: 48px auto 0;
        text-align: center;
        @include flexCenter();
        color: #fff;
      }
    }
  }
</style>
